import dynamic from 'next/dynamic'

const ContactForm = dynamic(() => import('./ContactForm'))
const CoverManager = dynamic(() => import('./CoverManager'))
const Custom = dynamic(() => import('./Custom'))
const DeliveryZone = dynamic(() => import('./DeliveryZone'))
const Faq = dynamic(() => import('./Faq'))
const FeaturedCategories = dynamic(() => import('./FeaturedCategories'))
const FullImageWithMessage = dynamic(() => import('./FullImageWithMessage'))
const Gallery = dynamic(() => import('./Gallery'))
const Hero = dynamic(() => import('./Hero'))
const ImageWithMessage = dynamic(() => import('./ImageWithMessage'))
const Instagram = dynamic(() => import('./Instagram'))
const JustAnImage = dynamic(() => import('./JustAnImage'))
const Links = dynamic(() => import('./Links'))
const MultiImageWithMessage = dynamic(() => import('./MultiImageWithMessage'))
const Popup = dynamic(() => import('./Popup'))
const ProductShowcase = dynamic(() => import('./ProductShowcase'))
const Publications = dynamic(() => import('./Publications'))
const Redirect = dynamic(() => import('./Redirect'))
const Referrals = dynamic(() => import('./Referrals'))
const Slider = dynamic(() => import('./Slider'))
const StartOrdering = dynamic(() => import('./StartOrdering'))
const StoreLocator = dynamic(() => import('./StoreLocator'))
const Stores = dynamic(() => import('./Stores'))
const WhiteSpace = dynamic(() => import('./WhiteSpace'))

export default {
  custom: Custom,
  coverManager: CoverManager,
  justAnImage: JustAnImage,
  gallery: Gallery,
  redirect: Redirect,
  hero: Hero,
  popup: Popup,
  featuredCategories: FeaturedCategories,
  startOrdering: StartOrdering,
  deliveryZone: DeliveryZone,
  slider: Slider,
  imageWithMessage: ImageWithMessage,
  links: Links,
  stores: Stores,
  faq: Faq,
  referrals: Referrals,
  contactForm: ContactForm,
  multiImageWithMessage: MultiImageWithMessage,
  whiteSpace: WhiteSpace,
  publications: Publications,
  instagram: Instagram,
  fullImageWithMessage: FullImageWithMessage,
  productShowcase: ProductShowcase,
  storeLocator: StoreLocator
}
