import pageFragment from '@data/fragments/website/pageFragment'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getWebsitePage_cached,
  getWebsitePage_cachedVariables
} from './__generated__/getWebsitePage_cached'

export const getWebsitePageQuery = gql`
  query getWebsitePage_cached($pageId: ID, $websiteId: ID) {
    page(pageId: $pageId, websiteId: $websiteId) {
      ...pageComponents
    }
  }
  ${pageFragment}
`

export default async function getWebsitePage(websiteId: string, pageId: string) {
  const {data} = await getClient().query<getWebsitePage_cached, getWebsitePage_cachedVariables>({
    query: getWebsitePageQuery,
    variables: {
      pageId,
      websiteId
    }
  })

  return data.page
}
