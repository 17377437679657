import {MdWifiOff} from 'react-icons/md'
import Button from '@components/V3/Button'

export default function Example() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
              <MdWifiOff />
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                  Este sitio esta desactivado
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  Para activarlo, contacta a cobranza@getjusto.com
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button primary onClick={() => (window.location.href = 'https://getjusto.com')}>
                  Ir a Justo
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
