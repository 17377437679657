import React from 'react'

import styles from './styles.module.css'

export default function NoWebsite(props) {
  return (
    <div className={styles.container}>
      <img src="/images/no-website.svg" className={styles.image} />
      <div className={styles.title}>Este sitio web no está configurado correctamente</div>
      <div>
        <a href="https://getjusto.com" className={styles.logo}>
          <img src="/logo-dark.svg" />
        </a>
      </div>
    </div>
  )
}
