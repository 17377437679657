import React from 'react'
import componentTypes from '@components/componentTypes'
import SafeSuspense from '@components/SafeSuspense'
import Loading from '@components/SuspenseLoading/Loading'
import {pageComponents} from '@data/fragments/website/__generated__/pageComponents'
import {
  getWebsitePage_cached,
  getWebsitePage_cachedVariables
} from '@data/queries/page/__generated__/getWebsitePage_cached'
import {getWebsitePageQuery} from '@data/queries/page/getWebsitePage'
import logger from '@helpers/logger'
import useEffect from '@hooks/useEffect'
import useTrack from '@hooks/useTrack'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'

export interface PageProps {
  pageId: string
  page?: pageComponents
  componentIdToSsrProps?: {[componentId: string]: any}
}

const SuspenseFallback = ({componentTypeId}: {componentTypeId: string}) => {
  logger.error(
    `Suspense gatillado para componente SSR (${componentTypeId}). Esto es un error de implementación. Revisar que el componente no esté usando una query que no sea partial.`
  )
  return <Loading />
}

export default function Page(props: PageProps) {
  let {page} = props
  const {componentIdToSsrProps = {}} = props
  const websiteId = useWebsiteId()

  const pageResults = useApolloQuery<getWebsitePage_cached, getWebsitePage_cachedVariables>({
    query: getWebsitePageQuery,
    variables: {pageId: props.pageId, websiteId},
    partial: true,
    omit: !!page
  })

  if (pageResults?.page) {
    page = pageResults.page
  }

  const track = useTrack()

  useEffect(() => {
    if (page) {
      track('viewPage', {path: page.path})
    }
  }, [page?._id])

  if (!page) return null

  return (
    <div>
      {page.activeComponents.map(component => {
        const Component = componentTypes[component.componentTypeId]
        const ssrProps = componentIdToSsrProps[component._id] ?? {}
        if (!Component) return `Component ${component.componentTypeId} not found`
        return (
          <div key={component._id} className={`ct-${component.componentTypeId}`}>
            <SafeSuspense
              fallback={<SuspenseFallback componentTypeId={component.componentTypeId} />}>
              <Component {...component.options} {...ssrProps} id={component._id} />
            </SafeSuspense>
          </div>
        )
      })}
    </div>
  )
}
