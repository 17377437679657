import SsgFallback from '@components/SsgFallback'
import getWebsiteIdFromDomain from '@data/queries/website/websiteIdFromDomain'
import getWebsiteInitialData from '@data/queries/website/websiteInitialData'
import getWebsitePagesInfo from '@data/queries/website/websitePagesInfo'
import websiteSSRTranslations from '@helpers/misc/websiteSSRTranslations'
import getSsrComponentMap from '@helpers/ssr/getSsrComponentMap'
import DeactivatedByDebt from '@page-components/DeactivatedByDebt'
import AbstractLayout from '@page-components/Layout/AbstractLayout'
import Website from '@page-components/Layout/Website'
import NoWebsite from '@page-components/NoWebsite'
import Page from '@page-components/Page'
import {InferGetStaticPropsType} from 'next'
import {useRouter} from 'next/router'

export default function PagesPage(props: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()
  if (router.isFallback) {
    return <SsgFallback />
  }

  if (!props.website) {
    return <NoWebsite />
  }

  if (props.website.isDeactivatedByDebt) {
    return <DeactivatedByDebt />
  }

  return (
    <AbstractLayout LayoutComponent={Website} initialData={props.initialData}>
      <Page
        pageId={props.page._id}
        page={props.page}
        componentIdToSsrProps={props.componentIdToSsrProps}
      />
    </AbstractLayout>
  )
}

export const getStaticProps = async context => {
  const websiteId = await getWebsiteIdFromDomain(context.params.hostname)
  const {website} = await getWebsitePagesInfo(websiteId)
  const initialData = await getWebsiteInitialData(websiteId)
  const slug = '/'

  const {componentIdToSsrProps, page} = await getSsrComponentMap(
    website,
    initialData,
    context.params.mobileOrDesktop,
    slug
  )

  const props = {
    ...(await websiteSSRTranslations(website)),
    websiteId,
    website,
    slug,
    page,
    componentIdToSsrProps,
    initialData,
    lastRevalidatedAt: new Date().toISOString()
  }

  if (website && !page) {
    return {
      props,
      redirect: {
        destination: '/pedir'
      },
      revalidate: 60 * 60 * 24
    }
  }

  return {
    props,
    revalidate: 60 * 60 * 24 // 1 day. It gets invalidated on demand when any server-side updates happen anyways.
  }
}

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: true
  }
}
