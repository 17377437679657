import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react'
import useIsDarkMode from '@hooks/useIsDarkMode'
import useKeyboardEvent from '@hooks/useKeyboardEvent'
import classNames from 'classnames'
import {useRouter} from 'next/router'

import SpinnerLoading from '../loadingIndicators/Spinner'

export interface ButtonProps {
  icon?: React.ElementType
  children: React.ReactNode
  onClick?: () => any
  to?: string
  disabled?: boolean
  loading?: boolean
  confirmText?: React.ReactNode

  /**
   * Pass a key event that will trigger the button click
   */
  clickOnKey?: string

  // design
  className?: string
  primary?: boolean
  danger?: boolean
  full?: boolean
  small?: boolean
  big?: boolean
  extraBig?: boolean
  contrast?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export interface ButtonRef {
  onClick: () => any
  click: () => any
  setOnClick: (onClick: () => any) => void
}

function Button(props: ButtonProps, ref: React.Ref<ButtonRef>) {
  const [loadingState, setLoading] = useState(false)
  const buttonRef = useRef(null)
  const loading = props.loading || loadingState
  const [confirm, setConfirm] = useState(false)
  const overrideOnClickRef = useRef<() => any>()
  const router = useRouter()
  const darkMode = useIsDarkMode()

  const onClick = async () => {
    if (props.disabled || loading) return

    if (props.confirmText) {
      if (confirm) {
        setConfirm(false)
      } else {
        setConfirm(true)
        return
      }
    }

    setLoading(true)
    if (overrideOnClickRef.current) {
      await overrideOnClickRef.current()
    } else {
      if (props.onClick) {
        await props.onClick()
      } else if (props.to) {
        router.push(props.to)
      }
    }

    // check if button is mounted
    if (buttonRef.current) {
      setLoading(false)
    }
  }

  const setOnClick = (onClick: () => any) => {
    overrideOnClickRef.current = onClick
  }

  useKeyboardEvent(props.clickOnKey ?? 'none', () => {
    if (!props.disabled && !loading && props.clickOnKey) {
      onClick()
    }
  })

  useImperativeHandle(ref, () => ({
    onClick,
    setOnClick,
    click: onClick,
    focus: () => {
      if (buttonRef.current) {
        buttonRef.current.focus()
      }
    }
  }))

  const style = props.disabled
    ? 'disabled'
    : props.primary
    ? 'primary'
    : props.danger
    ? 'danger'
    : props.contrast
    ? 'contrast'
    : 'default'

  const size = props.small ? 'small' : props.extraBig ? 'extraBig' : props.big ? 'big' : 'default'

  const className = classNames(
    {
      'inline-flex justify-center items-center shadow-sm rounded': true,
      'font-semibold': true,
      'transition ease-in-out duration-150': true,
      'focus:outline-none focus:ring-2 focus:ring-offset-2': true,

      'px-4 py-2 text-sm': size === 'default',
      'px-4 py-1 text-sm': size === 'small',
      'px-5 py-3 text-md': size === 'big',
      'px-5 py-4 text-md': size === 'extraBig',

      'cursor-not-allowed': props.disabled,
      'w-full': props.full,

      '!border-gray-100 text-gray-400': style === 'disabled',
      'border-gray-100 text-gray-700': style === 'default',
      'text-white': style === 'primary' || style === 'danger',

      'focus:ring-gray-300': style === 'default',
      'focus:button-v3-primary': style === 'primary',
      'focus:ring-red-500': style === 'danger',

      'bg-gray-100 border-gray-100': style === 'disabled' || style === 'default',
      'bg-red-500 border-red-500': style === 'danger',
      'button-v3-primary': style === 'primary',
      'bg-gray-900 border-gray-900 text-white': style === 'contrast' && !darkMode,
      'bg-white border-white text-gray-900': style === 'contrast' && darkMode,

      'hover:bg-gray-200':
        (!loading && style === 'default') || (style === 'contrast' && darkMode && !loading),
      'hover:button-v3-primary': !loading && style === 'primary',
      'hover:bg-red-400': !loading && style === 'danger',
      'hover:bg-gray-300': style === 'contrast' && !darkMode && !loading
    },
    props.className
  )

  return (
    <button
      ref={buttonRef}
      type={props.type ?? 'submit'}
      form="formId"
      disabled={props.disabled || loading}
      onClick={onClick}
      className={className}>
      {!loading && props.icon ? (
        <props.icon className="-ml-0.5 mr-2 w-4" aria-hidden="true" />
      ) : null}
      {loading ? (
        <SpinnerLoading className="mr-3" color={style === 'default' ? 'gray' : 'white'} />
      ) : null}
      {confirm ? props.confirmText : props.children}
    </button>
  )
}

export default forwardRef(Button)
